export default {
  namespaced: true,

  state: {
    index: 0
  },

  getters: {
    getIndex(state) {
      return state.index
    }
  },

  mutations: {
    SET_INDEX(state, index) {
      state.index = index
    }
  },
  actions: {
    setIndex({ commit }, index) {
      commit('SET_INDEX', index)
    }
  }
}
