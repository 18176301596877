<template>
  <div class="px-lg-4 px-md-4 px-0">
    <div v-if="paymentType !== '' && showPaymentModal">
      <ThePaymentModal
        v-bind:showModal="showPaymentModal"
        v-bind:paymentType="this.paymentType"
        v-bind:bookingId="this.bookingPrice.booking_id"
        @close="closePaymentModal"
      />
    </div>

    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="''"
      v-bind:description="this.contracts"
      @close="logChange"
    />

    <TheInsuranceInfoModal
      v-bind:showModal="displayInsuranceInfoModal"
      v-bind:title="$t('what_is_insurance_service')"
      @close="logChangeInsurance"
    />

    <div class="row">
      <div class="col-md-12">{{ $t('please_select_payment_method') }}</div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div
          @click="setPaymentType('No')"
          class="payment-type"
          v-bind:class="{ selectedPaymentType: paymentType === 'No' }"
        >
          <div class="header">{{ $t('no_payment_header') }}</div>
          <div class="sub-header">
            {{ $t('no_payment_subheader') }}
          </div>
          <p class="desc">
            {{ $t('no_payment_description') }}
          </p>
          <br />
          <div class="price-area">
            <div class="price-title">
              {{ $t('amount_to_be_paid_to_the_driver') }}
            </div>
            <div class="price-card">
              <div class="price-desc">{{ $t('approximately') }}</div>
              <div class="price-value">
                CHF {{ this.bookingPrice.discount_price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          @click="setPaymentType('Pre')"
          class="payment-type"
          v-bind:class="{ selectedPaymentType: paymentType === 'Pre' }"
        >
          <div class="header">
            <div class="header-left">
              <div>
                {{ $t('pre_payment_header') }}
              </div>
              <div class="orange-tag">{{ $t('free_insurance') }}</div>
            </div>

            <div class="header-right" @click="insuranceInfoClick">
              <i class="ri-info-i"></i> {{ $t('what_is_insurance_service') }}
            </div>
          </div>
          <div class="sub-header">{{ $t('pre_payment_subheader') }}</div>
          <p class="desc">
            {{ $t('pre_payment_description') }}
          </p>

          <div class="price-area mt-2">
            <div class="price-title">
              {{ $t('vip_transfer_service') }}
            </div>
            <div class="price">
              <div class="price-value">
                CHF {{ this.bookingPrice.discount_price }}
              </div>
            </div>
          </div>

          <div class="price-area mt-2">
            <div class="price-title">
              {{ $t('amount_to_be_deducted_from_your_credit_card_now') }}
            </div>
            <div class="price">
              <div class="price-value">
                CHF {{ this.bookingPrice.system_payment }}
              </div>
            </div>
          </div>

          <div class="price-area mt-2">
            <div class="price-title">
              {{ $t('amount_to_be_paid_to_driver') }}
            </div>
            <div class="price">
              <small>{{ $t('approximately') }}</small>
              <div class="price-value">
                CHF
                {{ this.bookingPrice.driver_payment }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          @click="setPaymentType('Full')"
          class="payment-type"
          v-bind:class="{ selectedPaymentType: paymentType === 'Full' }"
        >
          <div class="header">
            <div class="header-left">
              <div>
                {{ $t('full_payment_header') }}
              </div>
              <div class="green-tag">{{ $t('20_discount') }}</div>
              <div class="orange-tag">{{ $t('free_insurance') }}</div>
            </div>

            <div class="header-right" @click="insuranceInfoClick">
              <i class="ri-info-i"></i> {{ $t('what_is_insurance_service') }}
            </div>
          </div>

          <div class="sub-header">{{ $t('full_payment_subheader') }}</div>
          <p class="desc">
            {{ $t('full_payment_description') }}
          </p>
          <br />
          <div class="price-area">
            <div class="price-title">
              {{ $t('total_amount_to_be_paid_now') }}
            </div>
            <div class="price-card">
              <div class="price-desc">{{ $t('fixed') }}</div>
              <div class="price-value">
                CHF {{ this.bookingPrice.full_discount_price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="register-container">
      <div class="form-row">
        <div class="col-md-12">
          <div v-if="!showPayment">
            <div
              v-for="(item, index) in privacyArray"
              :key="index"
              class="privacy-item"
            >
              <div
                class="form-item form-check mr-3 mb-5 mt-5 align-items align-items-start"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="privacy"
                  v-model="item.selected"
                />
                <span class="form-check-label d-inline-block" for="privacy">
                  <a
                    href="javascript:;"
                    :id="item.id"
                    v-on:click="privacyDetail(item.id, $event)"
                    >{{ item.prefix }} {{ item.name }} {{ item.suffix }}.</a
                  >
                </span>
                <span
                  class="error text-danger"
                  v-if="
                    item.selected !== undefined &&
                    !$v.privacyArray.$each[index].selected.required &&
                    $v.privacyArray.$each[index].selected.$dirty
                  "
                >
                  {{ $t('value_is_required') }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <TheButton
              @click="
                showPayment == true
                  ? (showPayment = false)
                  : deccrementStepCount()
              "
              reverse="reverse"
              size="btn-sm"
              type="button"
              :disabled="false"
              >{{ $t('back') }}
            </TheButton>

            <TheButton
              @click="submit"
              :disabled="disabledPayment"
              v-if="paymentType !== ''"
              customClass="ml-3"
              size="btn-sm"
              >{{ $t('pay_now') }}
            </TheButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TheModal from '@/components/TheModal'
import TheInsuranceInfoModal from '@/components/TheInsuranceInfoModal'
import TheButton from '@/components/TheButton'
import ThePaymentModal from '@/components/ThePaymentModal'

import axios from 'axios'
export default {
  name: 'Register',
  components: {
    TheModal,
    TheButton,
    TheInsuranceInfoModal,
    ThePaymentModal
  },

  data() {
    return {
      showPaymentModal: false,
      displayInsuranceInfoModal: false,
      disabledPayment: true,
      showPayment: false,
      showModal: false,
      myIframe: null,
      contracts: null,
      position: null,
      prefix: null,
      required: null,
      selected: null,
      suffix: null,
      privacy: null,
      privacyForm: false,
      privacyArray: [],
      iframe: {
        loaded: false,
        src: '',
        style: null
      },
      name_sur_name: '',
      card_number: '',
      expired_month: '',
      expired_year: '',
      expired_ccv: '',
      advancePayment: false,
      paymentType: '',
      loading: false
    }
  },
  mounted() {
    console.log(this.bookingPrice)

    this.setIframeSrc()
    this.iframe.loaded = true

    var lang = 'en'
    if (localStorage.getItem('lang')) {
      lang = JSON.parse(localStorage.getItem('lang'))
    }

    axios
      .get('api/contracts/active?lang=' + lang)
      .then((response) => (this.contracts = response.data))
      .catch((error) => {
        this.errorMessage = error.message
        console.error('There was an error!', error)
      })

    axios
      .get('api/contracts/active?lang=' + lang)
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          for (const item of response.data) {
            if (item.position === 'payment') {
              this.privacyArray.push(item)
            }
          }
        }
        this.disabledPayment = false
      })
      .catch((error) => {
        this.errorMessage = error.message
        console.error('There was an error!', error)
      })
  },

  validations: {
    privacyArray: {
      $each: {
        selected: {
          required: function (val, item) {
            if (item.required) {
              return val
            }
            return true
          }
        }
      }
    }
  },

  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    closePaymentModal(event) {
      this.showPaymentModal = event
    },
    insuranceInfoClick() {
      this.displayInsuranceInfoModal = true
    },
    async noPayment() {
      const data = {
        bookingId: this.bookingId,
        paymentType: 'No'
      }
      var response = await axios.post(`api/payment`, data)

      if (response.status === 200) {
        this.$router.push({ path: 'reservations/' + this.bookingId })
      }
    },
    onLoad(frame) {
      this.myIframe = frame.contentWindow
    },
    logChange(event) {
      this.showModal = event
    },
    logChangeInsurance(event) {
      this.displayInsuranceInfoModal = event
    },
    setIframeSrc() {
      var baseUrl = process.env.VUE_APP_API_BASE_URL
      this.iframe.src = `${baseUrl}api/payment?bookingId=${this.bookingId}&paymentType=${this.paymentType}`
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        if (this.paymentType === 'No') {
          await this.noPayment()
        } else {
          if (this.paymentType === 'Full' || this.paymentType === 'Pre') {
            this.showPaymentModal = true
          }
        }
      }
    },

    privacyDetail(id, event) {
      console.log(id)
      console.log(event)
      this.showModal = true
      this.contracts = 'Loading...'

      axios
        .get(
          `api/contracts/active/${id}&payment?bookingId=${this.bookingId}&paymentType=${this.paymentType}`
        )
        .then((response) => (this.contracts = response.data))
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      this.setIndexAction(2)
    },
    ...mapActions({
      setIndexAction: 'bookingStep/setIndex'
    }),

    setPaymentType: function (type) {
      this.paymentType = type
      //this.setIframeSrc()

      console.log(type)
    }
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      bookingId: 'booking/getBookingId',
      bookingPrice: 'booking/getBookingPrice',
      selectedCar: 'booking/getSelectedCar'
    }),
    isDiscounted: function () {
      return (
        this.bookingPrice.discount_price !==
        this.bookingPrice.full_discount_price
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-type {
  border: 4px solid #ececec;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px;

  .header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left {
      display: flex;
      align-items: center;
    }

    .header-right {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 400;

      cursor: pointer;

      .ri-info-i:before {
        background: #00b9ff;
        color: white;
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .sub-header {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 12px;
    font-weight: 400;
  }
}

.advance-payment {
  background: rgba(248, 241, 227, 1);
  font-size: 13px;
  color: rgb(var(--text-color));
  b {
    margin-bottom: 8px;
  }
  .text-color-dark {
    font-size: 18px;
    color: rgb(var(--text-color));
  }
}
.pierce-area {
  small {
    text-decoration: line-through;
    color: rgb(var(--tertiary-color));
    font-size: 12px;
  }

  span {
    font-size: 18px;
    color: rgb(var(--secondary-color));
    font-weight: 600;
  }
}
.badge-color-success {
  background-color: rgb(var(--color-success));
  color: rgb(var(--white-color));
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 15px;
}
.payment-alert-info {
  font-size: 13px;
  color: rgb(var(--text-color));
  b {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .text-color-success {
    color: rgb(var(--color-success));
  }
}

.form-check {
  label {
    color: rgb(var(--primary-color));
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.payment-total-area {
  strong {
    max-width: 306px;
  }
}

.form-item {
  display: flex;
  flex-direction: column;
}
.price-area {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .price {
    small {
      font-size: 12px;
      color: #495057;
      display: inline-block;
      margin-right: 3px;
    }
    .price-value {
      display: inline;
      color: #00b9ff;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .price-title {
    font-size: 14px;
    font-weight: 500;
  }
  .price-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #202020;
    border-radius: 4px;
    .price-desc {
      color: white;
      border-right: 1px solid white;
      font-size: 11px;
      padding: 5px 10px;
    }

    .price-value {
      color: white;
      font-weight: bold;
      font-size: 18px;
      padding: 5px 10px;
    }
  }
}

.selectedPaymentType {
  border: 4px solid #00b9ff;
  background: #eefaff;

  .price-title {
    color: #00b9ff;
    font-size: 14px;
    font-weight: 500;
  }
}

.orange-tag {
  background: #ff7700;
  border-radius: 20px;
  color: white;
  font-size: 11px;
  padding: 3px 7px;
  display: inline-block;
  margin-left: 10px;
}

.green-tag {
  background: #22a72f;
  border-radius: 20px;
  color: white;
  font-size: 11px;
  padding: 3px 7px;
  display: inline-block;
  margin-left: 10px;
}
</style>
