<template>
  <div class="row">
    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="''"
      v-bind:description="this.contracts"
      @close="logChange"
    />

    <div class="col-lg-12 col-md-12 mb-2" v-if="registerForm">
      <h2>{{ $t('sign_up') }}</h2>
      <div class="register-container">
        <form @submit.prevent="submit" class="register w-100">
          <div
            class="form-group form-item"
            :class="{ 'form-group--error': $v.form.name.$error }"
          >
            <TheLabel>{{ $t('name') }} </TheLabel>
            <input
              class="form-control"
              :placeholder="$t('name')"
              v-model.trim="$v.form.name.$model"
            />
            <span
              class="error text-danger"
              v-if="!$v.form.name.required && $v.form.name.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>

          <div
            class="form-group form-item"
            :class="{ 'form-group--error': $v.form.sur_name.$error }"
          >
            <TheLabel>{{ $t('surname') }} </TheLabel>
            <input
              :placeholder="$t('surname')"
              class="form-control"
              v-model.trim="$v.form.sur_name.$model"
            />
            <span
              class="error text-danger"
              v-if="!$v.form.sur_name.required && $v.form.sur_name.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>

          <div
            class="form-item form-group"
            :class="{ 'form-group--error': $v.form.e_mail.$error }"
          >
            <TheLabel>{{ $t('email') }} </TheLabel>
            <input
              :placeholder="$t('email')"
              class="form-control"
              v-model.trim="$v.form.e_mail.$model"
            />
            <span
              class="error text-danger"
              v-if="!$v.form.e_mail.required && $v.form.e_mail.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>
          <div class="form-item">
            <TheLabel>{{ $t('mobile_phone') }}</TheLabel>

            <vue-tel-input
              v-bind="this.bindProps"
              v-model="form.phone_number"
              class="tel-input"
              :placeholder="$t('phone')"
              v-on:country-changed="countryChanged"
              :defaultCountry="defaultCountry"
              :key="componentKey"
              @validate="validatePhone"
            ></vue-tel-input>
            <span class="error text-danger" v-if="phoneIsValid === false">
              {{ $t('phone_is_not_valid') }}
            </span>
          </div>

          <div
            v-for="(item, index) in form.privacyArray"
            :key="index"
            class="privacy-item"
          >
            <div class="form-item form-check mr-3 mb-0">
              <input
                class="form-check-input"
                type="checkbox"
                id="privacy"
                v-model="item.selected"
              />
              <span class="form-check-label d-inline-block" for="privacy">
                <a
                  href="javascript:;"
                  :id="item.id"
                  v-on:click="privacyDetail(item.id, $event)"
                  >{{ item.prefix }} {{ item.name }} {{ item.suffix }}.</a
                >
              </span>
              <span
                class="error text-danger"
                v-if="
                  item.selected !== undefined &&
                  !$v.form.privacyArray.$each[index].selected.required &&
                  $v.form.privacyArray.$each[index].selected.$dirty
                "
              >
                {{ $t('value_is_required') }}
              </span>
            </div>
          </div>

          <div class="destination d-flex align-items-end form-btn-area">
            <TheButton customClass="mt-4" size="btn-sm" :loading="loading">{{
              $t('sign_up')
            }}</TheButton>

            <TheButton size="btn-sm ml-3" @click="close" reverse="reverse">{{
              $t('close')
            }}</TheButton>
          </div>
        </form>
      </div>
    </div>

    <div
      class="verification_code col-lg-12 col-md-12"
      v-if="loginVerification_code"
    >
      <SmsValidationForm
        :code="this.code"
        :phoneNumber="this.form.phone_number"
        :countryCode="this.form.country_code"
        validationType="register"
        @onVerifySuccess="onVerifySuccess"
        @onBack="onBack"
      ></SmsValidationForm>
    </div>
  </div>
</template>

<script lang="js">
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import TheButton from '@/components/TheButton'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import TheLabel from '@/components/TheLabel'
import TheModal from '@/components/TheModal'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import countries from '../../assets/telInputcountryData.js';
import SmsValidationForm from '@/components/Auth/SmsValidationForm'


export default {

  name: 'MyAccount',
  components: {
    TheButton,
    TheLabel,
    VueTelInput,
    TheModal,
    SmsValidationForm
  },
  data() {
    return {
      code:'',
      phoneIsValid: undefined,
      countries: countries,
      showModal: false,
      contracts: null,
      position: null,
      prefix: null,
      required: null,
      selected: null,
      suffix: null,
      form: {
        name: '',
        sur_name: '',
        e_mail: '',
        phone_number: '',
        country_code:"",
        privacy: false,
        privacyArray: []
      },
      //...
      loading: false,
      verificationCode: '',
      verification_code: true,
      loginVerification_code: false,
      myAccountVerifyCode: true,
      registerForm: true,
      registerResponsePhone: '',
      userId: '',
      otp: null,
      bindProps: {
        autofocus: false,
        autoFormat: true,
        inputOptions: {
          showDialCode: true
        }
      },
      defaultCountry: "CH",
      componentKey: 0,  // initial key

    }
  },


  props: {
    initialPhone: {
      type: String
    }
  },

  validations: {
    form: {
      name: { required },
      sur_name: { required },
      e_mail: { required },
      privacyArray: {
      $each: {
        selected: {
          required: function (val, item) {
            if (item.required) {
              return val;
            }
            return true;
          },
        },
      },
    },
    },
  },

  mounted() {

    if (this.initialPhone) {
      this.form.phone_number = this.initialPhone
    }

    var lang = 'en'
    if (localStorage.getItem('lang')) {
      lang = JSON.parse(localStorage.getItem('lang'))
    }

    axios.get("api/contracts/active?lang="+lang)
.then(response => {
  if(response.data && Array.isArray(response.data)) {
    for (const item of response.data) {
      if (item.position === "register") {
        this.form.privacyArray.push(item)
      }
    }
  }
})
.catch(error => {
  this.errorMessage = error.message;
  console.error("There was an error!", error);
});


  },

  methods: {
    onBack() {
      this.loginVerification_code = false
      this.registerForm = true;
    },
    onVerifySuccess() {
      this.$emit('onSuccess')
    },
    close() {
      this.$emit('onClose')
    },
    validatePhone(object) {
      this.phoneIsValid = object.valid;
    },
    countryChanged(country) {
      this.form.country_code = country.dialCode
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    ...mapActions({
      register: 'auth/register',
    }),
    submit() {

      this.$v.$touch()

      if (this.phoneIsValid !== true) {
        this.phoneIsValid=false;
      }

      if (this.$v.$invalid || this.phoneIsValid !== true) {
        this.submitStatus = 'ERROR'

      } else {
        this.loading = true
        this.submitStatus = 'PENDING'

        const data = {
          name: this.form.name,
          surname: this.form.sur_name,
          email: this.form.e_mail,
          phone: this.formatPhoneNumber(
            this.form.phone_number,
            `+${this.form.country_code}`
          ),
          country_code: `+${this.form.country_code}`,
          password: 'password'
        }

        this.register(data)
          .then((response) => {
            response = JSON.parse(response)
            if (response != null && response.otp) {
              this.code = response.otp.toString()
            }

            console.log(response)

            if (response.phone) {
              this.userId = response.user_id
              this.verification_code = false
              this.loginVerification_code = true
            } else {
              this.registerResponsePhone = data.phone
              this.loginVerification_code = false
            }


            this.loading = false
            this.registerForm = false

          })
          .catch((err) => {
            console.log(err.response);
            Swal.fire({
              title: this.$t('errors.login_failed'),
              text: err.response.data.error,
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
            this.loading = false
          })

        this.submitStatus = 'OK'

      }
    },
    logChange(event) {
      this.showModal = event
    },
    privacyDetail(id, event) {
    console.log(id);
    console.log(event);
    this.showModal = true;
    this.contracts = "Loading..."
    axios.get(`api/contracts/active/${id}`)
    .then(response => this.contracts = response.data)
    .catch(error => {
      this.errorMessage = error.message;
      console.error("There was an error!", error);
    });
  },

  }
}
</script>

<style lang="scss" scoped>
.register-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .register {
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .form-btn-area {
    a {
      font-size: 16px;
      color: rgb(var(--text-color));
      font-weight: 500;
    }
  }
}

.register-left-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h4 {
    color: var(--primary-color);
    text-align: left;
    font: normal normal 29px/43px Poppins;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 29px;
    margin-bottom: 30px;
  }

  span {
    color: var(--text-color);
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

h4 {
  font-size: 20px;
  color: rgb(var(--text-color));
  font-weight: 600;
}
</style>
