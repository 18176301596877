<template>
  <form @submit.prevent="signInOtp" class="register w-100">
    <div class="form-item">
      <div class="d-flex flex-row justify-content-between mb-3">
        <strong class="verify-code-title">{{ $t('verification_code') }}</strong>

        <a v-on:click="back" class="change-phone-number"
          ><i class="ri-arrow-left-line"></i> {{ $t('change_phone_number') }}</a
        >
      </div>
      <input
        class="form-control"
        :placeholder="$t('code')"
        v-model.trim="otp"
      />

      <p class="error text-danger" v-if="showLoginError">
        {{ this.$t('errors.login_failed_description') }}
      </p>
    </div>
    <div
      class="destination d-flex justify-content-between align-items-center form-btn-area"
    >
      <div class="d-flex justify-content-between w-100 mt-3">
        <TheButton
          :disabled="this.timeLeft === '0:00'"
          size="btn-sm"
          :loading="loading"
          >{{ $t('verify') }}</TheButton
        >
        <div class="d-flex flex-column justify-content-start align-items-end">
          <TheTimeCountDown
            minutes="1"
            seconds="30"
            ref="logoutTimer"
            @onTimerReset="didntGetTheCode"
            v-on:logout="notAuthenticated = true"
            inline-template
          >
            <span
              class="time-count-down"
              :class="timeLeft === '0:00' ? 'didnt-get-the-code' : ''"
              v-on:click="resetTimer()"
            >
              {{
                timeLeft === '0:00'
                  ? `${$t('didnt_get_the_code')}`
                  : timeLeft + 'sn.'
              }}
            </span>
          </TheTimeCountDown>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheTimeCountDown from '@/components/TheTimeCountDown'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'SmsValidationForm',

  props: {
    validationType: {
      type: String
    },
    userId: {
      type: Number,
      required: true
    },
    phoneNumber: {
      type: String
    },
    code: {
      type: String,
      required: false
    },
    countryCode: {
      type: String,
      required: false
    }
  },
  mounted() {
    if (this.code) {
      this.otp = this.code
    }
  },
  components: {
    TheButton,

    TheTimeCountDown
  },
  data() {
    return {
      showLoginError: false,
      loading: false,
      otp: null,
      timerFalse: false,
      timeLeft: null,
      form: {
        phone: null
      }
    }
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      getGenerate: 'auth/getGenerate',
      getRegister: 'auth/getRegister'
    })
  },
  methods: {
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    },
    onChange() {
      this.showLoginError = false
    },
    back() {
      this.$emit('onBack')
    },
    didntGetTheCode() {
      if (this.getGenerate) {
        this.form.phone = this.getGenerate.phone
      } else {
        this.form.phone = this.getRegister.phone
      }

      this.generate(this.form)
        .then((data) => {
          if (data !== null && data.user_id !== null) {
            this.userId = data.user_id
          }

          this.loading = false
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 400) {
              this.$router.replace({
                name: 'MyAccount'
              })
            }
            // ✅ log status code here
          }
        })
    },
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    ...mapActions({
      signIn: 'auth/signIn', //otp login
      registerValidate: 'auth/registerValidate', //otp login
      setIndexAction: 'bookingStep/setIndex',
      generate: 'auth/generate'
    }),

    async signInOtp() {
      if (this.validationType === 'register') {
        this.showLoginError = false
        this.loading = true
        let registerData = {
          otp: this.otp,
          phone: this.formatPhoneNumber(
            this.phoneNumber,
            '+' + this.countryCode
          ),
          country_code: '+' + this.countryCode
        }

        this.registerValidate(registerData)
          .then(() => {
            this.loading = false
            this.$emit('onVerifySuccess')
          })
          .catch(() => {
            this.loading = false
            this.showLoginError = true
          })
      } else {
        this.showLoginError = false
        this.loading = true
        let data = {
          otp: this.otp,
          user_id: this.userId
        }

        this.signIn(data)
          .then(() => {
            this.loading = false
            this.$emit('onVerifySuccess')
          })
          .catch(() => {
            this.loading = false
            this.showLoginError = true
          })
      }
    }
  }
}
</script>

<style lang="scss">
.loginError {
  color: red;
  margin-top: 10px;
  font-size: 12px;
}
.verify-code-title {
  text-align: left;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight: normal;
}

.change-phone-number {
  text-align: right;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #707070 !important;
  opacity: 1;
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
}
</style>
