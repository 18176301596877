import axios from 'axios'

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
    register: null,
    sign_in2: null,
    generate: null,
    country_code: null,
    dl_code: null,
    registerInfo: null
  },

  getters: {
    authenticated(state) {
      return !!(state.token && state.user)
    },
    user(state) {
      return state.user
    },
    getGenerate(state) {
      return state.generate
    },
    getRegister(state) {
      return state.registerInfo
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_REGISTER2(state, data) {
      state.register = data
    },
    SET_REGISTER_VALIDATE(state, data) {
      state.register = data
    },
    SET_SIGNIN2(state, data) {
      state.sign_in2 = data
    },
    SET_GENERATE(state, data) {
      state.generate = data
    },

    SET_REGISTER(state, data) {
      state.registerInfo = data
    }
  },
  actions: {
    async registerValidate({ dispatch }, credentials) {
      console.log(credentials)

      let response = await axios.post('api/registervalidate', credentials, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      return dispatch('attempt', response.data.token)
    },
    async registerValidate2({ commit }, credentials) {
      commit('SET_REGISTER2', credentials)
      let response = await axios.post('api/registervalidate', credentials, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      return response
    },
    async register({ dispatch, commit }, credentials) {
      commit('SET_REGISTER', credentials)
      let response = await axios.post('api/register', credentials, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      dispatch('attempt', response.token)
      return JSON.stringify(response.data)
    },

    async register2({ commit }, credentials) {
      commit('SET_REGISTER2', credentials)
      try {
        let response = await axios.post('api/register', credentials, {
          headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        })

        // response.status
        commit('SET_REGISTER_VALIDATE', response)
        return response
      } catch (error) {
        return error.response
      }
    },

    async generate({ dispatch, commit }, credentials) {
      commit('SET_GENERATE', credentials)

      let data = {
        phone: credentials.phone,
        country_code: credentials.country_code
      }

      let response = await axios.post('api/otp/generate', data, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      if (response.data.user_id) {
        return response.data
      } else {
        return dispatch('attempt', response.data.token)
      }
    },

    async signIn2({ commit }, credentials) {
      let response = await axios.post('api/otp/login', credentials)
      commit('SET_SIGNIN2', response)
      return response
    },

    async signIn({ dispatch }, credentials) {
      let response = await axios.post('api/otp/login', credentials)
      return dispatch('attempt', response.data.token)
    },
    async attempt({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      if (!state.token) {
        return
      }
      //TODO: Kullanıcı bilgileri
      try {
        let response = await axios.get('api/getCustomer')
        commit('SET_USER', response.data)
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },
    signOut({ commit }) {
      return axios.post('api/logout').then(() => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      })
    },
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/updateCustomer', data)
          .then((response) => {
            commit('SET_USER', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
