<template>
  <div>
    <div v-if="this.isResponseNotKmForm === true">
      <div v-if="items.length > 0">
        <TheChooseYourVehicleItem
          :baggage="item.baggage"
          :discount_price="item.discount_price"
          :name="item.name"
          :image="item.image"
          :person="item.person"
          :free_cancellation="item.free_cancellation"
          :price="item.price"
          :price_id="item.price_id"
          v-for="item in items"
          :key="item.price_id"
        />

        <div class="footer">
          <div class="footer-text">
            <div class="icon">
              <i class="ri-info-i"></i>
            </div>
            <div>
              {{
                $t('choose_your_vehicle_footer_text', {
                  0: this.distanceInKm,
                  1: this.durationInMinutes
                })
              }}
            </div>
          </div>
          <div class="footer-buttons">
            <TheButton
              reverse="reverse"
              size="btn-sm mt-3"
              @click="deccrementStepCount"
              >{{ $t('back') }}
            </TheButton>

            <TheButton @click="next" size="ml-4 btn-sm mt-3"
              >{{ $t('next_step') }}
            </TheButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="register-form">
      <TheResponseNotKmForm />
    </div>

    <div v-if="showLoginModal && formName != ''">
      <TheAuthModal
        :showModal="showLoginModal"
        :initialForm="'login'"
        @onSuccess="onSuccess"
        @onClose="onClose"
      />
    </div>
  </div>
</template>
<script>
// import TheLabel from '@/components/TheLabel'
import { mapGetters, mapActions } from 'vuex'
import TheAuthModal from '@/components/TheAuthModal'
import TheChooseYourVehicleItem from '@/components/TheChooseYourVehicleItem'
import TheResponseNotKmForm from '@/components/TheResponseNotKmForm'
import Swal from 'sweetalert2'
import TheButton from '@/components/TheButton'
import moment from 'moment'

export default {
  data() {
    return {
      showLoginModal: false,
      isResponseNotKmForm: true,
      settings: {
        arrows: false,
        dots: false,
        autoplay: false,
        slidesPerRow: 3,
        swipe: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: false,
              autoplay: false,
              slidesPerRow: 3,
              swipe: true,
              centerMode: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesPerRow: 1,
              infinite: true,
              centerMode: true
            }
          }
        ]
      },
      items: []
    }
  },

  components: {
    TheChooseYourVehicleItem,
    TheResponseNotKmForm,
    TheButton,
    TheAuthModal
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      cars: 'booking/getCarsList',
      selectedCar: 'booking/getSelectedCar'
    }),
    durationInMinutes() {
      return (this.duration / 60).toFixed(0)
    },
    distanceInKm() {
      return (this.distance / 1000).toFixed(0)
    }
  },
  async mounted() {
    const booking = this.$store.state.booking

    this.polyline_points = booking.polyline_points
    this.origin = booking.origin
    this.destination = booking.destination
    this.distance = booking.distance
    this.duration = booking.duration

    if (this.cars) {
      this.items = this.cars
      this.isResponseNotKmForm = true
      return
    }

    const data = {
      km: Math.ceil(this.distance / 1000)
    }

    const response = await this.calculatePriceAction(data)
    if (response.status === 200) {
      this.isResponseNotKmForm = true
      if (response.data.errors) {
        return Swal.fire({
          title: `${this.$t('custom_special_offer_text')}`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `${this.$t('yes')}`,
          denyButtonText: `${this.$t('no')}`
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.isResponseNotKmForm = false
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            this.setIndexAction(1)
            //this.$router.push({ name: 'Booking' })
          }
        })
      }

      this.items = response.data
    }

    this.loading = false
  },
  methods: {
    next() {
      if (this.authenticated === true) {
        this.createBooking()
      } else {
        this.showLoginModal = true
      }
    },

    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    },

    createBooking() {
      const data = {}

      const booking = this.$store.state.booking

      data.other_user_id = 0
      data.km = Math.ceil(booking.distance / 1000)
      data.duration = Math.ceil(booking.duration / 60)
      data.from = booking.origin.postal_code
      data.to = booking.destination.postal_code
      data.delivery_type = booking.delivery_type_id
      data.pickup_date = booking.pickup_date
      data.delivery_date = booking.delivery_date
      data.from_lat = booking.origin.lat
      data.from_lng = booking.origin.lng
      data.to_lat = booking.destination.lat
      data.to_lng = booking.destination.lng
      data.to_address = booking.destination.address
      data.to_name = booking.destination.name
      data.from_address = booking.origin.address
      data.from_name = booking.origin.name
      data.price_id = this.selectedCar.price_id
      data.booking_date = this.format_date(booking.fromDate)
      data.booking_time = this.format_time(booking.fromTime)
      const list = []
      data['list'] = list
      this.submitBookingAction(data).then(() => {
        this.setStep(4)
        this.submitStatus = 'OK'
        this.loading = false
      })
    },

    onSuccess() {
      this.showLoginModal = false
      this.createBooking()
    },

    onClose() {
      this.showLoginModal = false
    },

    deccrementStepCount() {
      //var incrementStep = this.step
      //incrementStep--
      this.setIndexAction(0)
    },

    ...mapActions({
      setIndexAction: 'bookingStep/setIndex',
      calculatePriceAction: 'booking/calculatePrice',
      submitBookingAction: 'booking/submitBooking'
    })
  }
}
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 27px;
      font-size: 12px;
      height: 20px;
      border: 1px solid #00b9ff;
      color: #00b9ff;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      text-align: center;
    }
  }
  .footer-buttons {
    color: green;
    min-width: 255px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
