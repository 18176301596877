<template>
  <div>
    <div class="header-wrapper">
      <div class="col-md-12 col-lg-4 d-flex align-items-center">
        <a v-if="this.logo" href="/" class="header-logo">
          <img :src="this.logo.value" />
        </a>
      </div>
      <div class="col-md-12 col-lg-auto">
        <div class="navigation">
          <router-link
            class="router-link"
            :class="getActiveClass('Booking')"
            to="/booking"
            >{{ $t('booking') }}</router-link
          >

          <div @click="login" v-if="!authenticated" class="router-link">
            {{ $t('reservations') }}
          </div>

          <router-link
            v-if="authenticated"
            class="router-link"
            :class="getActiveClass('Reservations')"
            to="/reservations"
            >{{ $t('reservations') }}</router-link
          >

          <template v-if="authenticated">
            <div class="login-area-container">
              <div
                class="router-link login-area"
                @click="toggleUserMenu"
                :class="getActiveClass('Login')"
              >
                <i class="ri-login-box-line mr-1"></i>
                {{ $t('my_account') }}
              </div>
              <ul
                class="user-menu"
                :class="[userMenuIsActive ? 'user-menu-active' : '']"
              >
                <li class="user-menu-item">
                  <router-link
                    to="/my-profile"
                    active-class="user-menu-item-active"
                  >
                    <i class="ri-user-line mr-2"></i>
                    {{ $t('my_profile') }}
                  </router-link>
                </li>
                <li class="user-menu-item">
                  <a href="#" @click.prevent="signOut"
                    ><Logout />{{ $t('sign_out') }}</a
                  >
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <div @click="login" class="router-link not-logged-in login">
              <span class="text d-flex align-items-center">
                <i class="ri-login-box-line mr-2"></i>
                {{ $t('login') }}
              </span>
              <span class="person"><i class="ri-user-3-fill"></i></span>
            </div>

            <div
              @click="register"
              class="router-link not-logged-in sign-up ml-3"
            >
              <span class="text d-flex align-items-center">
                <i class="ri-login-box-line mr-2"></i>
                {{ $t('sign_up') }}
              </span>
              <span class="person"><i class="ri-user-3-fill"></i></span>
            </div>
          </template>
          <template>
            <a
              @click="languageChange('en')"
              href="javascript:;"
              class="mr-1 ml-3"
            >
              <country-flag country="us" size="normal" />
            </a>
            <a href="javascript:;" @click="languageChange('de')">
              <country-flag country="de" size="normal" />
            </a>
          </template>
        </div>
      </div>
    </div>

    <div v-if="showLoginModal && formName != ''">
      <TheAuthModal
        :showModal="showLoginModal"
        :initialForm="formName"
        @onSuccess="onSuccess"
        @onClose="onClose"
      />
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import Logout from '@/assets/icons/logout.svg'
import CountryFlag from 'vue-country-flag'
import TheAuthModal from '@/components/TheAuthModal'

export default {
  name: 'TheNavigation',
  components: {
    Logout,
    CountryFlag,
    TheAuthModal
  },

  created() {

    this.$store.dispatch('homeSettings/setSettings').then(() => {
      this.logo =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_logo'
        )
    })
    window.addEventListener('click', (e) => {
      const classNames = [
        'user-menu-item',
        'router-link login-area',
        'toggle-button',
        'toggle-button-bar'
      ]
      if (!classNames.some((el) => el === e.target.className)) {
        this.userMenuIsActive = false
      }
    })
  },
  data() {
    return {
      userMenuIsActive: false,
      isMyAccountPage:false,
      logo:null,
      showLoginModal: false,
      formName: ''
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
     isDashboardActive () {
    return this.$route.path === '/myaccount'
  }
  },
  methods: {

    onClose() {
      this.showLoginModal = false
    },
    onSuccess() {
      this.showLoginModal = false
    },

    ...mapActions({
      signOutAction: 'auth/signOut',
      setIndexAction: 'bookingStep/setIndex'
    }),
    login() {
      this.formName = 'login'
      this.showLoginModal = true
    },
    register() {
      this.formName = 'register'
      this.showLoginModal = true
    },
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    signOut() {
      this.signOutAction().then(() => {
        this.$store.dispatch('booking/clearState')
        this.$router.replace({
          name: 'booking'
        })
      })
    },
    getActiveClass(key) {
      try {
        if (this.$route.name.startsWith(key)) {
          return 'router-link-active'
        }
        return ''
      } catch (error) {
        return ''
      }
    },
    toggleUserMenu() {
      this.userMenuIsActive = !this.userMenuIsActive
    },
    languageChange(lang) {
      localStorage.setItem('lang', JSON.stringify(lang))
        window.location.reload()
    }
  },
  watch: {
    $route (to){
       if (to.path == '/myaccount') {
      this.isMyAccountPage = true
       } else {
      this.isMyAccountPage = false

       }

    }
},
}
</script>

<style lang="scss" scoped>
.login {
  cursor: pointer;
}

.sign-up {
  cursor: pointer;
}
.user-menu-item-active {
  i {
    vertical-align: middle;
  }
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  flex-wrap: wrap;
  align-items: center;
  .header-logo img {
    max-width: 252px;
    width: 100%;
  }
}
.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid rgb(var(--border-color));

  .router-link {
    color: rgba(var(--primary-color), 0.5);
    font-size: 15px;
    margin-right: 30px;
    padding-bottom: 16px;
    padding-top: 16px;
    opacity: 0.5;
    cursor: pointer;
  }

  .router-link-active {
    color: rgba(var(--primary-color), 1);
    font-weight: bold;
    position: relative;
    opacity: 1;
  }

  .router-link-active:after {
    display: block;
    content: '';
    background: rgb(var(--secondary-color));
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -2px;
    top: auto;
    right: 0px;
  }
  .not-logged-in {
    //margin-left: auto;
    margin-right: 0;
    span.person {
      display: flex !important;

      @media (--md) {
        display: none !important;
      }
    }

    span.text {
      display: none !important;
      @media (--md) {
        display: flex !important;
      }
    }
  }

  .login-area-container {
    position: relative;
    margin-left: auto;
    margin-right: 0;
    .login-area {
      cursor: pointer;
      i {
        vertical-align: middle;
      }
    }

    .toggle-button {
      // width: 25px;
      // height: 20px;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // flex-direction: column;

      span {
        width: 100%;
        display: block;
        // background: #707070;
        height: 4px;
      }
    }

    .login-area {
      color: black;
      cursor: pointer;
      margin-right: 0;
    }

    .user-menu {
      position: absolute;
      top: 43px;
      right: 0;
      background-color: white;
      display: none;
      border: 1px solid #cccccc;
      width: 160px;
      z-index: 9;
    }
    .user-menu li {
      padding: 10px 20px;
    }
    .user-menu li + li {
      border-top: 1px solid #cccccc;
    }

    .user-menu li a {
      color: #777777;
    }

    .user-menu li a svg {
      margin-right: 10px;
    }

    .user-menu li a:hover {
      color: #111111;
    }

    .user-menu-active {
      display: block;
    }
  }

  .login-register-area {
    width: 100%;
    a {
      margin: 0px;
      margin-left: 10px;
    }
  }
}
</style>
