<template>
  <div v-if="showModal" v-on:close="showModal = false">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <h5 name="header">
            {{ this.title }}
          </h5>
          <div>
            <div class="text-row">
              <span>1</span>
              <div>{{ $t('insurance_modal_info_1') }}</div>
            </div>
            <div class="text-row">
              <span>2</span>
              <div>{{ $t('insurance_modal_info_2') }}</div>
            </div>
            <div class="text-row">
              <span>3</span>
              <div>{{ $t('insurance_modal_info_3') }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end">
            <TheButton v-on:click="$emit('close', false)" size="btn-sm">{{
              $t('close')
            }}</TheButton>
          </div>

          <!-- <button class="modal-default-button" v-on:click="showModal = false">
                Close
              </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'

export default {
  name: 'TheInsuranceInfoModal',

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  components: {
    TheButton
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.4s ease;
}

button {
  border: none;
  background: #f44336;
  color: #fff;
  padding: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.text-row {
  margin-bottom: 20px;
  display: flex;
  div {
    display: inline-block;
  }
  span {
    margin-right: 10px;
    display: inline-block;
    background-color: black;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 11px;
  }
}

.modal-container {
  width: 700px;
  margin: 0px auto;
  padding: 15px 15px;
  min-height: 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
  font-family: Helvetica, Arial, sans-serif;
  p {
    max-height: 600px;
    overflow: auto;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    p {
      max-height: 300px;
      overflow: auto;
    }
  }
}
</style>
