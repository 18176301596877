<template>
  <div @click="setSelectedCar" class="row">
    <div class="col-md-12" style="padding: 15px 30px">
      <div class="row car-item" v-bind:class="{ selectedCar: isCarSelected }">
        <div class="col-md-3">
          <img class="car-image" :src="this.image" />
        </div>
        <div class="col-md-9">
          <div class="row mt-5">
            <div class="col-md-9">
              <strong class="car-name">{{ this.name }}</strong>

              <ul class="car-features">
                <li>
                  <i class="ri-user-add-line"></i>
                  <span
                    >{{ $t('max') }}. {{ this.person }} {{ $t('person') }}</span
                  >
                </li>
                <li>
                  <i class="ri-suitcase-3-line"></i>
                  <span
                    >{{ $t('max') }}. {{ this.baggage }}
                    {{ $t('suitcase') }}</span
                  >
                </li>
                <li v-if="free_cancellation > 0">
                  <i class="ri-time-line"></i>
                  <span
                    >{{ $t('free_cancellation_up_to') }}
                    {{ free_cancellation }} {{ $t('hours') }}</span
                  >
                </li>
              </ul>
            </div>
            <div class="col-md-3 car-item-price-area">
              <small class="car-item-discounted-price" v-if="isDiscounted"
                >CHF {{ this.price }}</small
              >
              <span class="car-item-price">CHF{{ this.discount_price }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    baggage: String,
    discount_price: Number,
    image: String,
    name: String,
    person: String,
    price: Number,
    price_id: Number,
    free_cancellation: String
  },
  data() {
    return {
      isHovering: false
    }
  },
  methods: {
    setSelectedCar() {
      const selectedItem = {
        baggage: this.baggage,
        discount_price: this.discount_price,
        image: this.image,
        name: this.name,
        person: this.person,
        price: this.price,
        price_id: this.price_id,
        free_cancellation: this.free_cancellation
      }
      this.setSelectedCarAction(selectedItem)
    },
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      var incrementStep = this.step
      incrementStep--
      this.setIndexAction(incrementStep)
    },
    ...mapActions({
      setSelectedCarAction: 'booking/setSelectedCar',
      setIndexAction: 'bookingStep/setIndex'
    })
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      selectedCar: 'booking/getSelectedCar'
    }),
    isCarSelected() {
      return this.selectedCar?.price_id === this.price_id
    },
    getReverseClass() {
      if (this.isCarSelected) {
        return ''
      }
      return 'reverse'
    },
    isDiscounted: function () {
      return this.discount_price !== this.price
    }
  }
}
</script>
<style scoped>
.car-item-price-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.car-features {
  margin-top: 10px;
  font-size: 15px;

  li {
    i {
      display: inline-block;
      margin-right: 5px;
      color: #bbbbbb;
    }

    display: inline-block;
    margin-right: 5px;
    color: #202020;
  }
}

.car-item-price {
  color: #00b9ff;
  font-weight: 600;
  font-size: 25px;
}

.car-item-discounted-price {
  text-decoration: line-through;
  font-size: 14px;
  color: #202020;
  opacity: 0.5;
}
.slide {
  box-shadow: 0 6px 15px -3px rgb(0 0 0/0.3);
  padding: 25px;
  border-radius: 5px;
}

.car-item {
  border: 4px solid #ececec;
  border-radius: 5px;
  padding: 15px 5px;
  cursor: pointer;
}
.car-name {
  font-size: 18px;
  font-weight: 600;
}
.selectedCar {
  border: 4px solid #00b9ff;
  background: #eefaff;

  .car-name {
    color: #00b9ff;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
<style lang="scss" scoped>
.car-image {
  width: 200px;
  max-width: 200px;
  border-radius: 5px;
}
.hovering {
  border-bottom: 3px solid rgb(var(--secondary-color)) !important;
}
.carousel-item- {
  justify-content: flex-start;
}
.the-choose-your-vehicle-card {
  margin-right: 20px;
  margin-bottom: 20px;

  .the-choose-your-vehicle-img-box {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    img {
      width: 100%;
    }
  }

  .price-area {
    border-bottom: 3px solid rgb(var(--border-color));
    margin-bottom: 17px;
    padding-bottom: 7px;
    strong {
      font-size: 16px;
    }
    small {
      text-decoration: line-through;
      color: rgb(var(--tertiary-color));
      font-size: 12px;
    }

    span {
      font-size: 15px;
      color: rgb(var(--secondary-color));
      font-weight: 600;
    }
  }
  .button-container {
    width: 100%;
  }

  .feature {
    margin-bottom: 17px;

    abbr {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 17px;
        color: rgb(var(--tertiary-color));
      }
      span {
        font: normal normal normal 13px/25px Poppins;
        color: rgb(var(--text-color));
        font-weight: 500;
        text-align: left;
      }
    }
  }
}
</style>
