<template>
  <div v-if="showModal" v-on:close="showModal = false">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="activeForm === 'login'" id="login">
            <LoginForm
              @onSuccess="onSuccess"
              @onUserNotFound="onUserNotFound"
              @onClose="onClose"
            />
          </div>

          <div v-if="activeForm === 'register'" id="register">
            <RegisterForm
              :initialPhone="phone"
              @onSuccess="onSuccess"
              @onClose="onClose"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm.vue'
import RegisterForm from '@/components/Auth/RegisterForm.vue'

export default {
  name: 'TheAuthModal',
  mounted() {
    if (this.initialForm) {
      this.activeForm = this.initialForm
    }
  },

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    initialForm: {
      type: String
    }
  },
  components: {
    LoginForm,
    RegisterForm
  },
  data() {
    return {
      activeForm: '',
      phone: ''
    }
  },
  computed: {},
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    onSuccess() {
      this.$emit('onSuccess')
    },
    onUserNotFound(phone) {
      this.phone = phone
      this.activeForm = 'register'
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.4s ease;
}

button {
  border: none;
  background: #f44336;
  color: #fff;
  padding: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 700px;
  margin: 0px auto;
  padding: 15px 15px;
  min-height: 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
  font-family: Helvetica, Arial, sans-serif;
  p {
    max-height: 600px;
    overflow: auto;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    p {
      max-height: 300px;
      overflow: auto;
    }
  }
}
</style>
